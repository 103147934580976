<template>
  <b-tabs content-class="pt-1" pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">未提现列表</span>
      </template>
      <div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="搜索"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'nickname'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.nickname }}</span>
            </span>

            <!-- Column: tag -->

            <!-- Column: Status -->

            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="colorVariant(props.row.status)">
                {{ typeVariant(props.row.status) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'amount'">
              {{ props.row.amount / 100 }}&nbsp;元
            </span>
            <span v-else-if="props.column.field === 'create_time'">
              {{ timestampToTime(props.row.create_time) }}
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  variant="gradient-primary"
                  @click="paycash(props.row.id)"
                >
                  转账 </b-button
                >&nbsp;
                <b-button
                  variant="gradient-primary"
                  @click="payrefuse(props.row.id)"
                >
                  拒绝
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 显示 </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> {{ total }} 条 </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">已提现列表</span>
      </template>
      <div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="搜索"
                type="text"
                class="d-inline-block"
                @input="handleSearch1"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns1"
          :rows="rows1"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange1"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'nickname'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.nickname }}</span>
            </span>

            <!-- Column: tag -->
            <span v-else-if="props.column.field === 'tag'"> 设置 </span>
            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="colorVariant(props.row.status)">
                {{ typeVariant(props.row.status) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'amount'">
              {{ props.row.amount / 100 }}&nbsp;元
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 显示 </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange1"
                />
                <span class="text-nowrap"> {{ total1 }} 条 </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total1"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage1"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">被驳回列表</span>
      </template>
      <div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="搜索"
                type="text"
                class="d-inline-block"
                @input="handleSearch2"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns2"
          :rows="rows2"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange2"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'nickname'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.nickname }}</span>
            </span>

            <!-- Column: tag -->
            <span v-else-if="props.column.field === 'tag'"> 设置 </span>
            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="colorVariant(props.row.status)">
                {{ typeVariant(props.row.status) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'amount'">
              {{ props.row.amount / 100 }}&nbsp;元
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 显示 </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange2"
                />
                <span class="text-nowrap"> {{ total2 }} 条 </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total2"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage2"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BTabs,
  BTab,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    BTabs,
    BTab,
    VBModal,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },

  methods: {
    paycash(id) {
      useJwt
        .entPay({
          cid: this.cid,
          id: id,
        })
        .then((res) => {
          console.log("res", JSON.stringify(res));
          if (res.data.msg == "success") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "转账成功",
                icon: "CheckIcon",
                text: "👋 以转账给用户.",
                variant: "success",
              },
            });
            this.paycashlist();
            this.cashlist();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "转账失败",
                icon: "AlertTriangleIcon",
                text: "🚫" + `${res.data.msg}`,
                variant: "danger",
              },
            });
          }
        });
    },
    payrefuse(id) {
      useJwt
        .payrefuse({
          cid: this.cid,
          id: id,
        })
        .then((res) => {
          console.log("res", JSON.stringify(res));
          if (res.data.msg == "success") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "以驳回",
                icon: "CheckIcon",
                text: "👋 以驳回.",
                variant: "success",
              },
            });
            this.paycashlist();
            this.cashlist();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "驳回失败",
                icon: "AlertTriangleIcon",
                text: "🚫" + `${res.data.msg}`,
                variant: "danger",
              },
            });
          }
        });
    },
    handleSearch(searching) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 0,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .WxpaypageList({
                cid: this.cid,
                page: 1,
                status: 0,
                rows: this.pageLength,
                friendid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });
      this.search = searching;
      console.log(searching);
    },
    handleSearch1(searching) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 1,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total1 = res.data.data.records;
          if (this.total1 == 0) {
            useJwt
              .WxpaypageList({
                cid: this.cid,
                page: 1,
                status: 1,
                rows: this.pageLength,
                friendid: searching,
              })
              .then((res) => {
                this.total1 = res.data.data.records;
                this.rows1 = res.data.data.rows;
              });
          }
          this.rows1 = res.data.data.rows;
        });
      this.search = searching;
      console.log(searching);
    },
    handleSearch2(searching) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 2,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total2 = res.data.data.records;
          if (this.total2 == 0) {
            useJwt
              .WxpaypageList({
                cid: this.cid,
                page: 1,
                status: 2,
                rows: this.pageLength,
                friendid: searching,
              })
              .then((res) => {
                this.total2 = res.data.data.records;
                this.rows2 = res.data.data.rows;
              });
          }
          this.rows2 = res.data.data.rows;
        });
      this.search = searching;
      console.log(searching);
    },
    handleChangePage(page) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: page,
          status: 0,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log("翻页" + this.search);
    },
    handleChangePage1(page) {
      console.log("page" + page);
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: page,
          status: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total1 = res.data.data.records;
          this.rows1 = res.data.data.rows;
        });
      console.log("翻页1" + this.search);
    },
    handleChangePage2(page) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: page,
          status: 2,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total2 = res.data.data.records;
          this.rows2 = res.data.data.rows;
        });
      console.log("翻页2" + this.search);
    },
    handlePageChange(active) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          rows: active,
          status: 0,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    handlePageChange1(active) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          rows: active,
          status: 1,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows1 = res.data.data.rows;
          this.total1 = res.data.data.records;
        });
    },
    handlePageChange2(active) {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          rows: active,
          status: 2,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows2 = res.data.data.rows;
          this.total2 = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 0,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log(`${params[0].field} ${params[0].type}`);
    },
    onSortChange1(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total1 = res.data.data.records;
          this.rows1 = res.data.data.rows;
        });
      console.log(`${params[0].field} ${params[0].type}`);
    },
    onSortChange2(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 2,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total2 = res.data.data.records;
          this.rows2 = res.data.data.rows;
        });
      console.log(`${params[0].field} ${params[0].type}`);
    },
    paycashlist() {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 0,
          rows: this.pageLength,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    cashlist() {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 1,
          rows: this.pageLength,
        })
        .then((res) => {
          console.log("res.data.data.rows", res.data.data.rows);
          this.total1 = res.data.data.records;
          this.rows1 = res.data.data.rows;
        });
    },
    payrefuselist() {
      useJwt
        .WxpaypageList({
          cid: this.cid,
          page: 1,
          status: 2,
          rows: this.pageLength,
        })
        .then((res) => {
          console.log("res.data.data.rows", res.data.data.rows);
          this.total2 = res.data.data.records;
          this.rows2 = res.data.data.rows;
        });
    },
  },

  data() {
    return {
      pageLength: 10,
      total: 0,
      total1: 0,
      total2: 0,

      cid: 0,
      desc: "",
      search: "",
      deviceid: "",
      typename: "",
      gendername: "",
      module1: "",
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "所在微信",
          field: "accnickname",
        },
        {
          label: "提现微信id",
          field: "friendid",
        },
        {
          label: "提现微信名称",
          field: "cname",
        },
        {
          label: "提现金额",
          field: "amount",
        },
        {
          label: "提现时间",
          field: "cdatetime",
        },
        {
          label: "描述",
          field: "desc1",
        },
        {
          label: "订单号",
          field: "orderid",
        },
        {
          label: "状态",
          field: "status",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      columns1: [
        {
          label: "所在微信",
          field: "accnickname",
        },
        {
          label: "提现微信id",
          field: "friendid",
        },
        {
          label: "提现微信名称",
          field: "cname",
        },
        {
          label: "提现金额",
          field: "amount",
        },
        {
          label: "提交时间",
          field: "cdatetime",
        },
        {
          label: "转账时间",
          field: "updatetime",
        },
        {
          label: "描述",
          field: "desc1",
        },
        {
          label: "微信转账订单号",
          field: "paymentno",
        },
        {
          label: "状态",
          field: "status",
        },
      ],
      columns2: [
        {
          label: "所在微信",
          field: "accnickname",
        },
        {
          label: "提现微信id",
          field: "friendid",
        },
        {
          label: "提现微信名称",
          field: "cname",
        },
        {
          label: "提现金额",
          field: "amount",
        },
        {
          label: "提交时间",
          field: "cdatetime",
        },
        {
          label: "驳回时间",
          field: "updatetime",
        },
        {
          label: "描述",
          field: "desc1",
        },
        {
          label: "状态",
          field: "status",
        },
      ],
      rows: [],
      rows1: [],
      rows2: [],
      searchTerm: "",
    };
  },
  computed: {
    colorVariant() {
      const gendersColor = {
        /* eslint-disable key-spacing */
        0: "light-primary",
        1: "light-success",
        2: "light-danger",
        /* eslint-enable key-spacing */
      };
      return (status) => gendersColor[status];
    },

    typeVariant() {
      const typeVariant = {
        /* eslint-disable key-spacing */
        0: "未提现",
        1: "以提现",
        2: "被拒绝",

        /* eslint-enable key-spacing */
      };
      return (status) => typeVariant[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.paycashlist();
    this.cashlist();
    this.payrefuselist();
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
